@import "~app/mixins";

.surrounding {
  padding-left: 6px !important;
  color: $color-grey;

  .content {
    display: flex;
    flex-wrap: nowrap;

    .header {
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 15px;
        font-weight: 600;
        padding-bottom: 4px;
        color: #384048;
      }

      .actions {
        .action {
          cursor: pointer;
        }
      }
    }

    .left,
    .right {
      position: relative;
    }

    .left {
      width: 40%;

      .criteria {
        margin-bottom: 6px;

        .dataset {
          border: 1px solid #b0b6bb;
          background-color: #f3f5f5;
          border-radius: 6px;
          padding: 4px 10px;
          margin-bottom: 12px;

          .inner {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 15px;
            font-weight: bold;

            > svg {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }

            .datasets {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              > div {
                margin-left: 15px;
                cursor: pointer !important;

                label span {
                  padding-left: 5px;
                  font-size: 15px;
                  font-weight: bold;
                }

                &:nth-child(1) label span {
                  color: #319524;
                }

                &:nth-child(2) label span {
                  color: #2169c9;
                }

                &:nth-child(3) label span {
                  color: #e64f15;
                }
              }
            }
          }

          .nonDisclosure {
            margin: 6px 0;
            color: #2169c9;
            font-size: 13px;
          }
        }
        .fields {
          display: flex;
          flex-wrap: wrap;

          > div {
            width: 35%;
            margin-right: 3%;
            margin-bottom: 6px;

            &:nth-child(3n + 3) {
              width: 24%;
              margin-right: 0;
            }

            &.range {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;

              > div {
                width: 48%;

                input[type="text"] {
                  color: $color-grey-1;
                  margin-bottom: 2px;

                  @include placeholder($color-grey-1);
                }
              }
            }

            .landUseDropdown {
              width: 300px;
              max-height: 380px;
            }

            .radio {
              display: flex;
              justify-content: space-between;

              label span {
                padding-left: 3px;
              }
            }

            label {
              white-space: nowrap;
              color: $color-grey;
              margin-bottom: 4px;
            }

            input[type="text"],
            select,
            .dropdown {
              border-color: #cacaca;
              padding-left: 6px;
            }
          }
        }
      }

      .map {
        width: 100%;
        height: 405px;
        border: 1px solid $color-grey;
        position: relative;
      }

      .legend {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        margin-top: 8px;

        .colors {
          display: flex;

          .color {
            font-size: 11px;
            display: flex;
            align-items: center;
            margin-left: 4px;

            > :last-child {
              margin-top: 2px;
            }
          }
        }
      }
    }

    .right {
      padding: 0 0 0 15px;
      width: 58%;

      .fix {
        // 30px is 2 padding by 15px
        width: calc(100% - 30px);
      }

      .top {
        display: flex;
        justify-content: space-between;

        .titleContainer {
          position: relative;

          .title span {
            font-size: 14px;
          }

          .selected {
            position: absolute;
            top: 20px;
            margin-left: 7px;
            font-size: 13px;
            white-space: nowrap;
          }

          &.tall {
            .selected {
              top: 18px;
              font-size: 12px;
              line-height: 14px;
              white-space: pre;
            }
          }
        }

        .buttons {
          display: flex;
          align-items: center;

          .menuDropdown {
            width: 200px;
          }

          button {
            margin: 0 8px 0 0;
            padding-left: 13px;
            padding-right: 13px;
          }
        }
      }

      .tableComparableProperties {
        margin-top: 10px;
        overflow-x: hidden;
      }

      .grid {
        height: 630px;
        margin-top: 16px;

        svg {
          width: 20px;
          height: 20px;
        }

        .detail {
          text-align: center;

          button {
            width: 100px;
            margin: 10px 5px;
          }
        }

        :global {
          .ag-root {
            border-style: solid;

            .ag-cell {
              padding-top: 5px;
              line-height: 15px;
              white-space: normal;
            }
          }

          .ag-theme-balham .ag-header-cell:after {
            height: 22px;
          }
        }
      }

      .button {
        font-weight: normal;
        position: relative;
      }
    }

    &.embedded {
      height: 100%;
      overflow-y: auto;

      .left {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
      }
    }
  }

  .hidden {
    visibility: hidden;
  }
}

.camera {
  cursor: pointer;
  margin-left: 8px;
  width: 20px;
  height: 20px;
}

.indexCell {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    padding: 1px 3px;
    border-radius: 5px;
    font-size: 11px;
    line-height: 13px;

    &.saleSituation {
      color: white;
    }
  }

  svg {
    @include primary-fill-color();

    margin-right: 3px;
  }
}
